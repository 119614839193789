.userContainer {
  padding: 5px 5px 5px 12px;
  border-radius: 30px;
  @apply flex w-max cursor-pointer items-center border transition-shadow duration-150 ease-linear hover:shadow-md;
}

.menuIcon {
  width: 24px;
  height: 24px;
}

.languageSelector {
  @apply h-6 w-6 cursor-pointer transition-all duration-150 ease-linear hover:drop-shadow-md;
}

.userIcon {
  width: 30px;
  height: 30px;
}

.searchSection {
  /* padding: 16px 8px 16px 24px; */
  @apply w-full cursor-pointer rounded-full bg-transparent p-4 pl-6 text-base outline-none;
}

.searchSection::placeholder {
  @apply text-base text-black;
}

.searchSeperator {
  @apply h-6 w-px bg-slate-300;
}
