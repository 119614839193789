.b-datepicker {
  @apply relative;

  .b-datepicker-wrapper {
    @apply absolute top-0 z-20 rounded-md border border-slate-300 bg-white shadow-lg;
    & * {
      @apply text-xs md:!text-sm;
    }
  }

  .react-calendar {
    @apply w-[230px] rounded-md border-none md:w-[320px];
    font-family: var(--font-family);

    .react-calendar__navigation {
      @apply h-[35px] md:h-[44px];

      & .react-calendar__navigation__arrow,
      .react-calendar__navigation__label {
        @apply min-w-[35px] disabled:bg-white md:min-w-[44px];
      }
    }
  }

  .react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
  }

  .react-calendar__tile {
    @apply rounded enabled:hover:bg-slate-100 disabled:cursor-not-allowed disabled:rounded-none disabled:bg-white disabled:text-slate-300 disabled:line-through;

    &.react-calendar__tile {
      &--now {
        @apply bg-primary-100;
      }

      &--active,
      &--hasActive {
        @apply bg-primary-500 text-white hover:bg-primary-500 focus:bg-primary-500 enabled:bg-primary-500;
      }
    }
  }

  .react-calendar__month-view__days__day--weekend {
    @apply text-black;
  }

  .-selected-time {
    @apply bg-primary-500 text-white hover:bg-primary-500;
    svg {
      @apply !text-white;
    }
  }

  .-disabled {
    @apply !cursor-not-allowed text-slate-400 hover:!bg-white;
  }
}
.b-datepicker.-no-mobile {
  .b-datepicker-wrapper {
    @apply absolute top-0 z-20 rounded-md border border-slate-300 bg-white shadow-lg;
    & * {
      @apply !text-sm;
    }
  }

  .react-calendar {
    @apply !w-[320px];

    .react-calendar__navigation {
      @apply !h-[44px];

      & .react-calendar__navigation__arrow,
      .react-calendar__navigation__label {
        @apply !min-w-[44px];
      }
    }
  }
}

.rdrDateRangePickerWrapper,
.rdrCalendarWrapper {
  .rdrDefinedRangesWrapper {
    display: none;
  }
  .rdrDayToday .rdrDayNumber span:after {
    background: var(--clr-primary-500);
  }
  .rdrDay.rdrDayPassive {
    pointer-events: auto;

    /* .rdrDayNumber > span {
      color: #879bab;
    } */

    .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge,
    .rdrSelected,
    .rdrDayStartPreview,
    .rdrDayInPreview,
    .rdrDayEndPreview {
      display: block;
    }

    .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge,
    .rdrSelected {
      + .rdrDayNumber > span {
        color: rgba(255, 255, 255, 0.85);
      }
      + .rdrDayStartPreview,
      + .rdrDayEndPreview,
      + .rdrDayInPreview {
        + .rdrDayNumber > span {
          color: rgba(255, 255, 255, 0.85);
        }
      }
    }

    .rdrDayStartPreview,
    .rdrDayInPreview,
    .rdrDayEndPreview {
      + .rdrDayNumber > span {
        color: #1d2429;
      }
    }
  }

  /* TODO: burada previewler arasi bi bozukluk mevcut kontrol edilmeli */
  /* ! ay sonu ve basinda sapitiyor */

  /* aylar arasi intersectionlarda borderlari kaldirip geri ekleme */
  .rdrDayEndOfMonth .rdrInRange,
  .rdrDayEndOfMonth .rdrStartEdge:not(.rdrEndEdge),
  .rdrDayEndOfMonth .rdrDayInPreview,
  .rdrDayEndOfMonth .rdrDayStartPreview:not(.rdrDayEndPreview),
  /* .rdrDayEndOfMonth .rdrDayEndPreview:not(.rdrDayStartPreview), */
  .rdrDayEndOfMonth .rdrDayInPreview {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 0;
    right: 0;
  }

  .rdrDayEndOfMonth.rdrDayEndOfWeek .rdrInRange,
  .rdrDayEndOfMonth.rdrDayEndOfWeek .rdrStartEdge,
  .rdrDayEndOfMonth.rdrDayEndOfWeek .rdrEndEdge,
  .rdrDayEndOfMonth.rdrDayEndOfWeek .rdrDayInPreview,
  .rdrDayEndOfMonth.rdrDayEndOfWeek .rdrDayStartPreview,
  .rdrDayEndOfMonth.rdrDayEndOfWeek .rdrDayEndPreview,
  .rdrDayEndOfMonth.rdrDayEndOfWeek .rdrDayInPreview {
    border-top-right-radius: 1.042em;
    border-bottom-right-radius: 1.042em;
    border-right-width: 1;
    right: 2px;
  }

  .rdrDayStartOfMonth .rdrInRange,
  .rdrDayStartOfMonth .rdrEndEdge:not(.rdrStartEdge),
  .rdrDayStartOfMonth .rdrDayInPreview,
  /* .rdrDayStartOfMonth .rdrDayStartPreview:not(.rdrDayEndPreview), */
  .rdrDayStartOfMonth .rdrDayEndPreview:not(.rdrDayStartPreview),
  .rdrDayStartOfMonth .rdrDayInPreview {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-width: 0;
    left: 0;
  }

  .rdrDayStartOfMonth.rdrDayStartOfWeek .rdrInRange,
  .rdrDayStartOfMonth.rdrDayStartOfWeek .rdrStartEdge,
  .rdrDayStartOfMonth.rdrDayStartOfWeek .rdrEndEdge,
  .rdrDayStartOfMonth.rdrDayStartOfWeek .rdrDayInPreview,
  .rdrDayStartOfMonth.rdrDayStartOfWeek .rdrDayStartPreview,
  .rdrDayStartOfMonth.rdrDayStartOfWeek .rdrDayEndPreview,
  .rdrDayStartOfMonth.rdrDayStartOfWeek .rdrDayInPreview {
    border-top-left-radius: 1.042em;
    border-bottom-left-radius: 1.042em;
    border-left-width: 1;
    left: 2px;
  }
}
