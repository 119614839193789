@font-face {
  font-family: 'Lato';
  src: url('https://cdn.teknevia.com/fonts/latolatin-semibold.woff2')
    format('woff2');
  font-style: normal;
  font-weight: 600;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('https://cdn.teknevia.com/fonts/latolatin-semibolditalic.woff2')
    format('woff2');
  font-style: italic;
  font-weight: 600;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('https://cdn.teknevia.com/fonts/latolatin-regular.woff2')
    format('woff2');
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('https://cdn.teknevia.com/fonts/latolatin-medium.woff2')
    format('woff2');
  font-style: normal;
  font-weight: 500;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('https://cdn.teknevia.com/fonts/latolatin-mediumitalic.woff2')
    format('woff2');
  font-style: italic;
  font-weight: 500;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('https://cdn.teknevia.com/fonts/latolatin-italic.woff2')
    format('woff2');
  font-style: italic;
  font-weight: 400;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('https://cdn.teknevia.com/fonts/latolatin-light.woff2')
    format('woff2');
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('https://cdn.teknevia.com/fonts/latolatin-lightitalic.woff2')
    format('woff2');
  font-style: italic;
  font-weight: 300;
  text-rendering: optimizeLegibility;
  font-display: swap;
}
