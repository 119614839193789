@import './variables.css';
@import 'react-toastify/dist/ReactToastify.css';
@import './fonts.css';
@import './swiper.css';
@import './react-calendar.css';

html,
body {
  font-family: var(--font-family);
  scrollbar-gutter: stable;
  @apply text-base;
}

#__next {
  min-height: 100%;
  position: relative;
  @apply mb-[var(--bottom-navigation-menu-height)] md:mb-0;
}

.b-label-wrapper .react-tel-input {
  --clr-slate-200: rgb(226 232 240 / 1);
  --dropdown-border-radius: 0.25rem 0 0 0.25rem;
}

.b-label-wrapper .react-tel-input .form-control {
  position: relative;
  border: none;
  height: auto;
  width: 100%;
  font-size: 16px;
  line-height: inherit;

  @apply rounded py-2 pl-12 pr-4;
}

.b-label-wrapper .react-tel-input .flag-dropdown {
  border: none;
  border-right: 1px solid var(--clr-slate-200);
  border-radius: var(--dropdown-border-radius) !important;

  @apply bg-slate-50;
}
.b-label-wrapper .react-tel-input .flag-dropdown.open .selected-flag {
  border-radius: var(--dropdown-border-radius) !important;
}

.navbar.-transparent svg path {
  fill: white;
  stroke: transparent;
  @apply text-white;
}
.navbar.-transparent {
  box-shadow: none;
  background-color: transparent;
}

.tkv-container {
  @apply container px-[var(--padding-container-x)];
}

@tailwind base;

@layer base {
  html {
    @apply text-black;
  }
}
@tailwind components;
@tailwind utilities;

.cursor-not-allowed * {
  cursor: not-allowed;
}

input[type='number'].remove-input-arrows::-webkit-inner-spin-button,
input[type='number'].remove-input-arrows::-webkit-inner-spin-button {
  @apply m-0 appearance-none;
}

.naked-input {
  @apply !appearance-none !border-none !bg-transparent !px-0 !pt-0 !pb-0 !shadow-none !outline-none focus:!outline-none;
}

.breadcrumb-item:not(:last-child)::after {
  content: '>';
  @apply ml-1 inline-block text-inherit;
}

.seo-content {
  @apply mt-10 text-sm;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply my-2 text-base;
  }
}

.mobile-banner {
  background: linear-gradient(to bottom, rgb(29, 114, 122), rgb(23, 75, 95));
}

.translate-z-0 {
  transform: translateZ(0);
}

.fading-bottom::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  height: 12px;
}

.clamp-3-line {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
