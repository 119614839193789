/* base variables */
:root,
::after,
::before {
  --font-family: 'Lato', ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;

  --bottom-navigation-menu-height: calc(
    63px + env(safe-area-inset-bottom, 0px)
  );

  --padding-container-x: 1rem;

  --clr-primary-100: rgb(207, 249, 233);
  --clr-primary-200: rgb(162, 243, 219);
  --clr-primary-300: rgb(109, 219, 197);
  --clr-primary-400: rgb(69, 184, 171);
  --clr-primary-500: rgb(23, 138, 135);
  --clr-primary-600: rgb(16, 112, 118);
  --clr-primary-700: rgb(11, 86, 99);
  --clr-primary-800: rgb(7, 62, 80);
  --clr-primary-900: rgb(4, 46, 66);

  --clr-success-100: rgb(208, 251, 205);
  --clr-success-200: rgb(157, 247, 160);
  --clr-success-300: rgb(105, 231, 122);
  --clr-success-400: rgb(66, 208, 99);
  --clr-success-500: rgb(16, 178, 70);
  --clr-success-600: rgb(11, 153, 72);
  --clr-success-700: rgb(8, 128, 71);
  --clr-success-800: rgb(5, 103, 66);
  --clr-success-900: rgb(3, 85, 62);

  --clr-info-100: rgb(218, 218, 252);
  --clr-info-200: rgb(188, 189, 249);
  --clr-info-300: rgb(158, 160, 246);
  --clr-info-400: rgb(129, 131, 244);
  --clr-info-500: rgb(99, 102, 241);
  --clr-info-600: rgb(84, 87, 205);
  --clr-info-700: rgb(69, 71, 169);
  --clr-info-800: rgb(54, 56, 133);
  --clr-info-900: rgb(40, 41, 96);

  --clr-warning-100: rgb(254, 239, 207);
  --clr-warning-200: rgb(253, 219, 159);
  --clr-warning-300: rgb(249, 191, 111);
  --clr-warning-400: rgb(243, 164, 75);
  --clr-warning-500: rgb(235, 123, 19);
  --clr-warning-600: rgb(202, 94, 13);
  --clr-warning-700: rgb(169, 69, 9);
  --clr-warning-800: rgb(136, 48, 6);
  --clr-warning-900: rgb(112, 33, 3);

  --clr-danger-100: rgb(255, 208, 206);
  --clr-danger-200: rgb(255, 172, 167);
  --clr-danger-300: rgb(255, 135, 128);
  --clr-danger-400: rgb(255, 135, 128);
  --clr-danger-500: rgb(255, 61, 50);
  --clr-danger-600: rgb(217, 52, 43);
  --clr-danger-700: rgb(179, 43, 35);
  --clr-danger-800: rgb(140, 34, 28);
  --clr-danger-900: rgb(102, 24, 20);

  --clr-black: rgb(8, 47, 62);
  --clr-white: rgb(255, 255, 255);

  --clr-gray-50: rgb(248, 250, 252);
  --clr-gray-100: rgb(241, 245, 249);
  --clr-gray-200: rgb(226, 232, 240);
  --clr-gray-300: rgb(203, 213, 225);
  --clr-gray-400: rgb(148, 163, 184);
  --clr-gray-500: rgb(100, 116, 139);
  --clr-gray-600: rgb(71, 85, 105);
  --clr-gray-700: rgb(51, 65, 85);
  --clr-gray-800: rgb(30, 41, 59);
  --clr-gray-900: rgb(15, 23, 42);
}

/* toastify variables */
:root,
::after,
::before {
  --toastify-color-light: var(--clr-white);
  --toastify-color-dark: var(--clr-black);
  --toastify-color-info: var(--clr-info-500);
  --toastify-color-success: var(--clr-success-500);
  --toastify-color-warning: var(--clr-warning-500);
  --toastify-color-error: var(--clr-danger-500);
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-danger);

  --toastify-toast-background: var(--clr-white);
  --toastify-font-family: 'Lato', sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: var(--clr-black);
  --toastify-text-color-dark: var(--clr-white);

  /* Used only for colored theme */
  --toastify-text-color-info: var(--clr-white);
  --toastify-text-color-success: var(--clr-white);
  --toastify-text-color-warning: var(--clr-white);
  --toastify-text-color-error: var(--clr-white);

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  /* Used when no type is provided */
  --toastify-color-progress-light: var(--clr-black);

  /* Used when no type is provided */
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

/* swiper variables */
:root,
::after,
::before {
  --swiper-theme-color: var(--clr-primary-300) !important;
  --swiper-navigation-color: var(--swiper-theme-color);
  --swiper-pagination-bullet-inactive-color: var(--swiper-theme-color);
  --swiper-pagination-bullet-inactive-opacity: 0.6;
  --swiper-pagination-bullet-width: 6px;
  --swiper-pagination-bullet-height: 6px;

  --swiper-navigation-size: 20px;
}
