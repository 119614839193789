.swiper-pagination-fraction,
.swiper-pagination-fraction .swiper-pagination-current,
.swiper-pagination-fraction .swiper-pagination-total {
  text-shadow: 0px 0px 2px var(--clr-primary-900);
  @apply text-white;
}

.responsive-carousel .swiper-slide {
  width: unset;
}

/* .swiper-button-prev,
.swiper-button-next {
  @apply !hidden;
} */
